import "./style.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../../Images/logo.png';
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function Footer() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  return (
    <div className="footer"   ref={ref2}
    style={{
      transform: isInView2 ? "none" : "translateY(250px)",
      opacity: isInView2 ? 1 : 0,
      transition: `all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s`
    }}>
      <hr></hr>
      <div className="foots">
        <div className="foot">
          <img src={logo} alt="logo" className="footer-logo" />
      
        </div>
        <div className="foot">
          <h4> Informations </h4> 
          <p className="footer-link">
         <strong style={{color:"#011"}}> adresse</strong><br/><br/>
         Rue Patrice Lumumba , Beb Bhar . Sfax
           </p>
           <p className="footer-link">
             <strong style={{color:"#011"}}> horaires</strong><br/><br/>
             LUN – VEND: 09:00 À 19:00<br/>
                 SAM: 09:00 À 17:00
           </p>
        </div>

        <div className="foot">
          <h4>contacts</h4>
          <a href="tel:53656288" className="footer-link">
          (+216) 53 656 288
          </a>
          <div>
          <a
            href="https://www.facebook.com/profile.php?id=100028842476605"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon sx={{width:{lg:36,md:'34px',sm:'24px',xs:'20px'}}}/>
          </a>
          <a
            href="https://www.instagram.com/saveurs_de_fatma"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon sx={{width:{lg:36,md:'32px',sm:'22px',xs:'18px'}}}/>
          </a>
          </div>
        </div>
      </div>
     
      <hr></hr>
      <div className="lastFoot">
        <h5 style={{ color: "GrayText" }}>
          © 2024, Saveurs de Fatma. All Rights Reserved.  
        Développé par   <a
                className="footer-link" style={{color:'#af7c80'}}
                href="https://h4c.pro/"
              > by H4C </a>
            

        </h5>
      </div>
    
    </div>
  );
}
